<template>
	<base-layout>
		<div class="containerBox">
			<ion-input
				placeholder="Email"
				type="email"
				class="inputBox email"
				required="true"
				id="email"
				v-on:ionInput="storeEmail"
			></ion-input>
			<ion-input
				placeholder="Password"
				type="password"
				class="inputBox password"
				required="true"
				id="pass"
				v-on:ionInput="storePassword"
				minlength="7"
			></ion-input>
			<ion-button class="inputBox signInButton" @click="onButtonClick"
				>Sign In</ion-button
			>
			<h3 class="noMatchText" id="noMatch" @click="resetPassword">
				Password incorrect. Reset password?
			</h3>
		</div>
	</base-layout>
</template>

<script>
	import {
		IonButtons,
		IonBackButton,
		IonInput,
		IonButton,
		IonNavLink,
	} from "@ionic/vue";

	import { emailSignIn } from "@/services/firebase.js";

	export default {
		methods: {
			backToSignIn() {
				this.$router.push("/signin");
			},
			onButtonClick() {
				if (this.password != "" && this.email != "") {
					emailSignIn(this.email, this.password)
						.then((res) => {
							console.log(res);
							window.location.replace("/dashboard");
						})
						.catch((e) => {
							console.log(e);
							if (e.code == "auth/wrong-password") {
								document.getElementById("noMatch").style.display = "block";
								console.log("wrong pass");
							}
						});
				}
			},
			storeEmail() {
				this.email = document.getElementById("email").value;
			},
			storePassword() {
				this.password = document.getElementById("pass").value;
			},
			resetPassword() {
				this.$router.push("/signin/email/resetpassword");
			},
		},
		components: [IonButtons, IonBackButton, IonInput, IonButton, IonNavLink],
		data() {
			return {
				email: "",
				password: "",
				passwordsDoNotMatch: false,
			};
		},
		mounted() {
			document.getElementById("noMatch").style.display = "none";
			/*let firebaseScript = document.createElement('script')
        firebaseScript.setAttribute('src', 'https://www.gstatic.com/firebasejs/9.1.3/firebase-app.js')
        document.head.appendChild(firebaseScript)*/
			console.log(document);
		},
	};
</script>

<style scoped>
	.inputBox {
		color: var(--ion-color-light-contrast);
		background: var(--ion-color-light-tint);
		/*border: 2px solid white;*/
		width: 80%;
		left: 10%;
		position: absolute;
		height: 40px;
		box-shadow: 2px;
		--padding-start: 10px;
	}
	.email {
		top: 0px;
	}
	.password {
		top: 60px;
	}
	.signInButton {
		top: 120px;
		color: black;
		font-weight: bold;
	}
	.containerBox {
		width: 100%;
		height: 240px;
		position: absolute;
		left: 0px;
		top: calc(50% - 150px);
	}
	.noMatchBox {
		position: absolute;
		top: 230px;
	}
	.noMatchText {
		color: red;
		font-size: 14px;
		position: absolute;
		bottom: 35px;
		width: 100%;
		text-align: center;
		text-decoration: underline;
	}
</style>
